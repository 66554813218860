import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import "./modalSelectMap.css";
import helper from "utils/helper";
import { getTruckType } from "api/masterData/truckType";
// const google = window.google;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "80vh",
  //   height: 200,
  overflow: "scroll",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  pt: 3,
  px: 3,
  pb: 3,
};
const ModalSelectTruck = (props) => {
  const { value, close, open, onClick, truckType } = props;
  const [truck, setTruck] = useState();
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();
  useEffect(() => {
    if (!truckType && open) {
      getTruck();
    } else {
      setTruck(truckType);
    }
  }, [truckType, open]);

  const getTruck = async () => {
    let truck = await getTruckType({
      sort_field: "order_number",
      sort_order: 1,
    });
    console.log(truck);
    setTruck(truck.data.data.results);
  };
  return (
    <div>
      <Modal open={open} className="modal-select-truck">
        <Box sx={{ ...style }}>
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              close();
            }}
          >
            <CloseIcon />
          </div>
          <div style={{ marginTop: 40 }}>
            {truck?.map((ele, index) => {
              return (
                <div key={index + ele.name} onClick={() => onClick(ele)}>
                  <div
                    className={
                      "modal-select-truck-options " +
                      (ele?.id === value?.id
                        ? "modal-select-truck-options-select"
                        : "")
                    }
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={helper.addhtpps(ele.image)}
                        height={50}
                        alt={"truck"}
                      />
                    </div>
                    <div style={{ flex: 1, textAlignLast: "start" }}>
                      <div
                        className="modal-select-truck-label"
                        style={{ fontWeight: 500 }}
                      >
                        {ele.name}
                      </div>
                      <div
                        className="modal-select-truck-label"
                        style={{ fontWeight: 300 }}
                      >
                        {ele.width +
                          " x " +
                          ele.length +
                          " x " +
                          ele.height +
                          " ม. " +
                          ele.weight +
                          " กก."}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSelectTruck;
