import MapWithCall from "components/map/MapWithCall";
import React, { useState, useEffect } from "react";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import HelperMap from "utils/helperMap";
import SwipeUp from "components/swipe/SwipeUp";
import { useForm, Controller } from "react-hook-form";
import masterProvice from "api/masterData/provice";
import masterProduct from "api/masterData/productType";
import { getTruckType } from "api/masterData/truckType";
import "./CheckWorkPrice.css";
import Button from "@mui/material/Button";
import ButtonSwitch from "components/button/buttonSwitch";
import SelectLocation from "components/work/selectLocation";
import helper from "utils/helper";
import Swal from "sweetalert2";
import CopyToClipboard from "react-copy-to-clipboard";
import API from "api/work/work";
import { Divider, Typography } from "@mui/material";
import SelectTruck from "components/work/selectTruck";
import SelectService from "components/work/selectService";
import FieldSelect from "components/field/FieldSelect";
const api = API.create();
const CheckWorkPrice = () => {
  const [buttonWorkType, setButtonWorkType] = useState([
    {
      icon: require("assets/images/work/ftl.png"),
      title: "บริการเหมาคัน",
      subtitle: "ส่งของเยอะเหมาทั้งคัน",
      active: true,
    },
    {
      icon: require("assets/images/work/ltl.png"),
      title: "บริการฝากส่ง",
      subtitle: "ส่งของชิ้นใหญ่จำนวนน้อย",
      active: false,
    },
  ]);
  const [truckType, setTruckType] = useState();
  const [productType, setProductType] = useState();
  const [province, setProvice] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);
  const [call, setCall] = useState();
  const [loc, setLoc] = useState();
  const [loadMap, setLoadmap] = useState(false);
  const [clipboardInfo, setClipboardInfo] = useState(null);
  const [price, setPrice] = useState(0);
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      shipper_code: "",
      stl_standard_size_id: 3,
      location: [
        {
          type: 0,
          name: "",
          lat: "",
          long: "",
          address: "",
          district: "",
          subdistrict: "",
          province: "",
          postal_code: "",
          contact_name: "",
          contact_phone: "",
          place_name: "",
          place_type_id: 0,
          remark: "",
          document: "",
          document_file: [],
          product: [
            {
              name: "",
              product_id: "",
              product_name: "",
              product_size: "",
              product_width: "",
              product_length: "",
              product_height: "",
              product_volume: "",
              product_weight: "",
              product_count_type: "",
              product_amount: "",
              product_image: [null, null, null, null, null],
              product_image_per_route: [],
            },
          ],
        },
        {
          type: 1,
          name: "",
          lat: "",
          long: "",
          address: "",
          district: "",
          subdistrict: "",
          province: "",
          postal_code: "",
          contact_name: "",
          contact_phone: "",
          place_name: "",
          place_type_id: 0,
          remark: "",
          document: "",
          document_file: [],
          product: [
            {
              name: "",
              product_id: "",
              product_name: "",
              product_size: "",
              product_width: "",
              product_length: "",
              product_height: "",
              product_volume: "",
              product_weight: "",
              product_count_type: "",
              product_amount: "",
              product_image: [null, null, null, null, null],
              product_image_per_route: [],
            },
          ],
        },
      ],
      truck: null,
      service: {
        has_document_return: false,
        document_return_code: "",
        document_return_address: "",
        document_return_district: "",
        document_return_subdistrict: "",
        document_return_province: "",
        document_return_postal_code: "",
        document_return_contact_name: "",
        document_return_contact_phone: "",
        has_labor: false,
        labor_load_amount: 0,
        labor_unload_amount: 0,
      },
      price: {
        shipper: {
          shipper_document_return_fee: null, // ค่าเอกสารนำกลับ
          shipper_labor_load_fee: null, // ค่าพนักงานขึ้นสินค้า
          shipper_labor_unload_fee: null, // ค่าพนักงานลงสินค้า
          shipper_add_drop_point_fee: null, // ค่าเพิ่มจุดส่ง
          shipper_transport_fee: null, // ค่าจัดส่ง
          shipper_freight_before_tax: null, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
          shipper_freight_tax: null, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
          shipper_freight_net: null, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
          shipper_has_deposit: null, // มีเงินมัดจำ
          shipper_deposit_amount: null, // จำนวนเงินมัดจำ
          commission: null, //ค่านายหน้า
        },
        carrier: {
          carrier_freight: null, // ค่าจ้างผู้ขนส่ง
          carrier_saas_fee: null, // ค่าธรรมเนียมระบบ
          carrier_freight_before_tax: null, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
          carrier_freight_tax: null, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
          carrier_penalty: 0, // ค่าปรับกรณีทำผิดข้อตกลง
          carrier_insurance_per_trip: null, // ค่าประกันภัยสินค้ารายเที่ยว
          carrier_transfer_fee: null, // ค่าธรรมเนียมการโอนเงิน
          carrier_freight_net: null, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
        },
        difference: {
          price_difference: null,
          price_difference_percentage: null,
        },
      },
    },
    deposit: {
      has_deposit: 0,
      deposit_amount: "",
    },
  });

  const watchLocation = watch("location");
  const watchShipperId = watch("shipper_id");
  const watchTruck = watch("truck");
  const watchService = watch("service");
  const watchRemark = watch("remark");
  const watchPostName = watch("post_name");
  const watchRouteId = watch("route_id");
  const watchDistance = watch("distance");
  const STLsize = watch("stl_standard_size_id");

  useEffect(() => {
    let origin = watchLocation?.filter((ele, index) => ele.type === 0);
    let destination = watchLocation?.filter((ele, index) => ele.type === 1);
    console.log("watchService", watchService);
    let orig_detail = origin?.map((ele, index) => {
      return `\n🔺จุดรับ: ${ele.subdistrict} ${ele.district} ${ele.province} ( ${ele.lat}, ${ele.long} )`;
    });

    let dest_detail = destination?.map((ele, index) => {
      return `\n🔻จุดส่ง${index + 1}: ${ele.subdistrict} ${ele.district} ${
        ele.province
      } ( ${ele.lat}, ${ele.long} )`;
    });

    let service;
    if (buttonWorkType[0].active) {
      service =
        !watchService.has_labor && !watchService?.has_document_return
          ? " -"
          : `${watchService?.has_document_return ? "เอกสารนำกลับ," : ""}${
              watchService.has_labor && watchService?.labor_load_amount !== 0
                ? `พนักงานขึ้นสินค้า ${watchService?.labor_load_amount} คน,`
                : ""
            }${
              watchService.has_labor && watchService?.labor_unload_amount !== 0
                ? `พนักงานลงสินค้า ${watchService?.labor_unload_amount}คน`
                : ""
            }`;
    }

    let text =
      "📋บริการ: " +
      (buttonWorkType[0].active ? "เหมาคัน" : "ฝากส่ง") +
      orig_detail[0] +
      dest_detail.map((val) => {
        return val;
      }) +
      "\n🛣️ระยะทาง: " +
      (watchDistance?.orderDistance ?? 0) +
      " กม." +
      "\n🚚ประเภทรถ: " +
      (buttonWorkType[0].active ? watchTruck?.name : "รถกระบะ 4 ล้อ") +
      (buttonWorkType[0].active
        ? "\n🅾️บริการเสริม: " + service
        : "\n🅾️ขนาดสินค้า: " +
          (STLsize === 1 ? "SIZE S" : STLsize === 2 ? "SIZE M" : "SIZE L")) +
      "\n💵ค่าขนส่ง: " +
      price +
      " บาท";

    setClipboardInfo(text);
  }, [
    price,
    watchLocation,
    watchTruck,
    watchShipperId,
    watchDistance,
    watchService,
    buttonWorkType,
    STLsize,
  ]);
  useEffect(() => {
    if (!truckType) {
      getTruck();
    }
    if (!productType) {
      getProduct();
    }
    // getProvince();
    // getDistrict();
    // getSubDistrict();
    // getFuel();
  }, []);

  useEffect(() => {
    if (loc && loc.length > 0) {
      setRoute();
    }
  }, [loc]);
  const setRoute = async () => {
    let has_marker = false;
    let list = [];
    for (const { ele, index } of loc.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.lat !== "" && ele.long !== "") {
        console.log("set dot route", ele.lat, ele.long);
        const dot = await new window.longdo.Marker(
          { lat: ele.lat, lon: ele.long },
          {
            draggable: false,
            clickable: true,
            weight: longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class=""><img src="' +
                (ele.type === 0
                  ? require("assets/images/work/pickup_pin.png")
                  : require("assets/images/work/send_pin.png")) +
                '" style="height: 30px"/></div>',
            },
          }
        );
        list.push(dot);
        console.log("dot", dot);
        has_marker = true;
      }
    }
    // watchLocation.forEach((ele, index) => {});
    console.log("has_marker", has_marker);
    console.log("list", list);
    let dis = await HelperMap.getDistance(loc);
    setValue("distance", dis);
    setCall(list);
  };

  const getProvince = async () => {
    let pv = await masterProvice.Province();
    let mapPv = pv.data.data.results?.map((ele) => {
      return { ...ele, item: ele.name, value: ele.id };
    });
    // console.log(mapPv);
    setProvice(mapPv);
  };
  const getDistrict = async () => {
    let dis = await masterProvice.District();
    let mapDis = dis.data.data.results.map((ele) => {
      return {
        ...ele,
        item: ele.name,
        value: ele.id,
      };
    });
    console.log(mapDis);
    setDistrict(mapDis);
  };
  const getSubDistrict = async () => {
    let subdis = await masterProvice.SubDistrict();
    let mapDis = subdis.data.data.results.map((ele) => {
      return {
        ...ele,
        item: ele.name,
        value: ele.id,
      };
    });
    // console.log(mapDis);
    setSubDistrict(mapDis);
  };
  const getProduct = async () => {
    let product = await masterProduct.ProductType({
      sort_field: "order_number",
      sort_order: 1,
      active: true,
    });
    // console.log(product);
    setProductType(product.data.data.results);
  };
  const getTruck = async () => {
    let truck = await getTruckType({
      sort_field: "order_number",
      sort_order: 1,
      active: true,
    });
    console.log("truck", truck);
    let truck_default = truck.data.data.results.filter(
      (e) => e.order_number === 1
    );
    console.log("truck_default", truck_default[0]);
    if (truck_default.length > 0) {
      setValue("truck", truck_default[0]);
    }
    setTruckType(truck.data.data.results);
  };

  useEffect(() => {
    getGeneralPrice();
  }, [
    STLsize,
    watchLocation,
    watchTruck,
    watchShipperId,
    watchDistance,
    watchService,
    buttonWorkType,
  ]);
  const getGeneralPrice = async () => {
    setPrice(0);
    let origin_province = [];
    let origin_district = [];
    let origin_subdistrict = [];
    let destination_province = [];
    let destination_district = [];
    let destination_subdistrict = [];
    console.log("watchLocationwatchLocation", watchLocation);
    watchLocation.forEach((ele, index) => {
      if (ele.type === 0) {
        origin_province.push(ele.province);
        origin_district.push(ele.district);
        origin_subdistrict.push(ele.subdistrict);
      } else {
        destination_province.push(ele.province);
        destination_district.push(ele.district);
        destination_subdistrict.push(ele.subdistrict);
      }
    });
    let body = {
      // shipper_id: workDetail?.shipper_id,
      distance:
        watchDistance?.allDistance > 0
          ? Math.ceil(watchDistance?.allDistance)
          : 1,
      truck_type: watchTruck?.id ?? 1,
      // truck_type: 1,
      origin_province: origin_province,
      origin_district: origin_district,
      origin_subdistrict: origin_subdistrict,
      destination_province: destination_province,
      destination_district: destination_district,
      destination_subdistrict: destination_subdistrict,
      doc_return: watchService?.has_document_return,
      load_amount: watchService?.labor_load_amount,
      unload_amount: watchService?.labor_unload_amount,
      service_id: buttonWorkType[0].active ? 1 : 2,
      profile_type: 1,
    };
    if (body?.service_id === 2) {
      body.stl_standard_size_id = STLsize;
    }
    console.log("bodybody", body);
    await api
      .getGeneralPricePublic(body)
      .then((response) => {
        console.log("general price ", response.data);
        setPrice(response?.data?.results?.shipper.price_before_tax);
      })
      .catch((error) => {
        console.log("error general price ", error);
        if (error.response.data.code === 4406) {
          Swal.fire({
            // timer: 2000,
            title: error.response.data.description,
            icon: "error",
            // showConfirmButton: false,
          });
        }
      });
  };
  return (
    <div className="container">
      <MapWithCall call={call} />
      <SwipeUp
        onSwipe={() => {
          if (!loadMap) {
            setLoadmap(true);
          }
        }}
        fullSwiped={true}
        minimized40={true}
        headerContent={
          <div className="headSwipeWork">
            <div
              style={{
                width: 50,
                height: 5,
                backgroundColor: "#8ca3ba",
                position: "absolute",
                top: 2.5,
                transform: "translate(-50%, -50%)",
                borderRadius: "0px 0px 10px 10px",
                left: "50%",
              }}
            ></div>
            <div
              style={{
                fontSize: "12px",
              }}
            >
              แตะเพื่อเปิด/ปิด
            </div>
            <div>ระยะทางประมาณ {watchDistance?.orderDistance ?? 0} กม.</div>
            <div>
              ระยะเวลาเดินทางประมาณ{" "}
              {watchDistance?.orderInterval ?? "- ชั่วโมง"}
            </div>
          </div>
        }
      >
        <div style={{ marginBottom: 220 }}>
          <ButtonSwitch
            option={buttonWorkType}
            onClick={(value) => {
              let tmp = [...buttonWorkType];
              tmp.forEach((ele, i) => {
                if (i === value) {
                  ele.active = true;
                } else {
                  ele.active = false;
                }
              });
              setButtonWorkType(tmp);
              if (value === 1) {
                let tmp = [watchLocation[0], watchLocation[1]];
                setValue("location", tmp);
                let truck_default = truckType.filter(
                  (e) => e.order_number === 1
                );
                setValue("truck", truck_default[0]);
              }
            }}
          />
          <div>
            <SelectLocation
              value={watchLocation}
              onChange={(value) => {
                setValue("location", value);
                let location = value.map((e) => {
                  return { lat: e.lat, long: e.long, type: e.type };
                });
                console.log("loc", location);
                setLoc(location);
              }}
              profile_id={watchShipperId}
              workType={buttonWorkType[0].active ? "ftl" : "stl"}
              closeFav={true}
            />
            <Divider orientation="horizontal" />
            {buttonWorkType[0].active === true ? (
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectTruck
                    value={value}
                    onChange={(val) => {
                      setValue("truck", val);
                      console.log("watchService before", watchService);
                      let tmp = { ...watchService };
                      tmp.has_labor = false;
                      tmp.labor_load_amount = 0;
                      tmp.labor_unload_amount = 0;
                      setValue("service", tmp);
                      console.log("watchService after", watchService);
                    }}
                    truckType={truckType}
                  />
                )}
                name="truck"
                defaultValue=""
              />
            ) : (
              <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 0.4,
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <img
                    src={require("assets/images/work/truck.png")}
                    style={{ height: 40, objectFit: "contain", width: "auto" }}
                    alt={"truck"}
                  />
                </div>
                <div style={{ flex: 0.6 }}>รถกระบะ 4ล้อ</div>
              </div>
            )}
            <Divider orientation="horizontal" />
            {buttonWorkType[0].active === true ? (
              <>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <SelectService
                      value={value}
                      onChange={(val) => {
                        setValue("service", val);
                      }}
                      truck={watchTruck}
                      is_public={true}
                    />
                  )}
                  name="service"
                  defaultValue=""
                />
                <Divider orientation="horizontal" />
              </>
            ) : (
              <div style={{ paddingTop: 20 }}>
                <Typography>ขนาดสินค้า</Typography>
                <Typography>
                  SIZE L น้ำหนักไม่เกิน 500 ก.ก. ปริมาตรไม่เกิน 1.5 ลบ.ม.
                </Typography>
                <Divider orientation="horizontal" />
                <div style={{ display: "flex", alignItems: "center" ,flexDirection:'column' ,paddingTop:'20px' }}>
                  <p style={{ color: "red", fontWeight: 300,lineHeight:0.2 }}>
                    ถ้าน้ำหนักและปริมาตรสินค้าเกินกำหนด
                  </p>
                  <p style={{ color: "red", fontWeight: 300,lineHeight:0.2 }}>
                    โปรดใช้บริการเหมาคันหรือติดต่อเจ้าหน้าที่
                  </p>
                </div>
              </div>
              // <Controller
              //   control={control}
              //   rules={{
              //     required: true,
              //   }}
              //   name="stl_standard_size_id"
              //   render={({ field: { onChange, onBlur, value } }) => (
              //     <div style={{ paddingTop: 20 }}>
              //       <FieldSelect
              //         name="ขนาดสินค้า"
              //         options={[
              //           {
              //             value: 1,
              //             name: "SIZE S น้ำหนักไม่เกิน 100 ก.ก. ปริมาตรไม่เกิน 0.5 ลบ.ม.",
              //           },
              //           {
              //             value: 2,
              //             name: "SIZE M น้ำหนักไม่เกิน 300 ก.ก. ปริมาตรไม่เกิน 1 ลบ.ม.",
              //           },
              //           {
              //             value: 3,
              //             name: "SIZE L น้ำหนักไม่เกิน 500 ก.ก. ปริมาตรไม่เกิน 1.5 ลบ.ม.",
              //           },
              //         ]}
              //         value={value}
              //         onChange={onChange}
              //       />
              //     </div>
              //   )}
              // />
            )}
          </div>
          <div className="fixed-button">
            <div
              style={{
                fontSize: "24px",
                padding: "10px",
              }}
            >
              ค่าขนส่ง {helper.addComma(price)} บาท
            </div>
            <CopyToClipboard
              text={clipboardInfo}
              onCopy={() => {
                Swal.fire({
                  title: "คัดลอกสำเร็จ",
                  icon: "success",
                });
              }}
            >
              <div style={{ paddingBottom: "10px", width: "100%" }}>
                <Button
                  variant="contained"
                  style={{
                    width: "70%",
                    height: "40px",
                    borderRadius: "10px",
                    backgroundColor: "#EC1F26",
                  }}
                >
                  คัดลอกข้อความเส้นทางที่เช็กราคา
                </Button>
              </div>
            </CopyToClipboard>
            <div style={{ paddingBottom: "30px", width: "100%" }}>
              <Button
                variant="contained"
                style={{
                  width: "70%",
                  height: "40px",
                  borderRadius: "10px",
                  backgroundColor: "#06c755",
                }}
                onClick={() => window.open("https://lin.ee/rgF1Nya", "_blank")}
              >
                เปิด LINE เจ้าหน้าที่ เพื่อส่งข้อความ
              </Button>
            </div>
          </div>
        </div>
      </SwipeUp>
    </div>
  );
};

export default CheckWorkPrice;
