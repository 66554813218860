// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headSwipeWork {
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.fixed-button {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  padding-top: 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CheckPrice/CheckWorkPrice.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,kBAAkB;EAClB,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,iBAAiB;EACjB;2CACyC;AAC3C","sourcesContent":[".headSwipeWork {\n  padding: 5px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.fixed-button {\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  text-align: center;\n  width: 100%;\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  border-radius: 20px 20px 0 0;\n  padding-top: 20px;\n  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,\n    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
