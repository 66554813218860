import helper from "utils/helper";

const create = () => {
  const getGeneralPricePublic = async (data) => {
    let api = await helper.setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/public/get-general-price" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  const getWorkDetailPublic = async (param) => {
    let api = await helper.setAPI();
    return api.get("api/work/public/get-work-public", {
      params: param,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  const getDriverLoc = async (param) => {
    let api = await helper.setAPI();
    return api.get("api/work/driver-location", {
      params: param,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const getTrackingFromCode = async (param) => {
    let api = await helper.setAPI();
    return api.get(`api/work/get-tracking`, {
      params: param,
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "application/json",
      },
    });
  };
  return {
    getGeneralPricePublic,
    getWorkDetailPublic,
    getDriverLoc,
    getTrackingFromCode,
  };
};

export default { create };
