import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import helper from "utils/helper";

const SelectTruck = (props) => {
  const [show, setShow] = useState(false);
  const { value, onChange = () => {}, truckType, is_detail } = props;
  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (!is_detail) clickShow();
        }}
      >
        <div
          style={{
            flex: 0.5,
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px 10px 0px",
          }}
        >
          <img
            src={
              value
                ? helper.addhtpps(value.image)
                : require("assets/images/work/truck.png")
            }
            style={{ height: 40, objectFit: "contain", width: "auto" }}
            alt={"truck"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div style={{ fontWeight: 500 }}>{value?.name ?? "ประเภทรถ"}</div>
          <div style={{ fontWeight: 300, fontSize: 14 }}>
            {value
              ? value.width +
                " x " +
                value.length +
                " x " +
                value.height +
                " ม. " +
                value.weight +
                " กก."
              : "-"}
          </div>
        </div>
        {is_detail ? null : (
          <div style={{ alignSelf: "center" }}>
            <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
          </div>
        )}
      </div>
      <ModalSelectTruck
        open={show}
        value={value}
        truckType={truckType}
        onClick={(val) => {
          onChange(val);
          clickClose();
        }}
        close={() => {
          clickClose();
        }}
      />
    </div>
  );
};
export default SelectTruck;
