import helper from "utils/helper";
let api = await helper.setAPI();
export const getTruckType = async (params = {}) => {
  try {
    return await api.get(`api/master/truck-type`, {
      params: params,
      //   headers:{
      //     "Content-Type"
      //   }
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getTruckTypeById = async (id = null) => {
  try {
    return await api.get(`api/master/truck-type/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};


export const checkTruckTypeFromTruckCode = async (truck_code = "") => {
  try {
    return await api.get(
      `api/master/truck-type/check-data-from-truck-code/${truck_code}`
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

