import helper from "utils/helper";
let api = await helper.setAPI();
const Province = async (params = {}) => {
  try {
    return await api.get(`api/master/province`, {
      params: params,
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const District = async (params = {}) => {
  try {
    return await api.get(`api/master/district`, {
      params: params,
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const SubDistrict = async (params = {}) => {
  try {
    return await api.get(`api/master/subdistrict`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const DistrictID = async (id) => {
  try {
    return await api.get(`api/master/district/${id}`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const SubDistrictID = async (id) => {
  try {
    return await api.get(`api/master/subdistrict/${id}`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
const masterProvice = {
  Province,
  District,
  SubDistrict,
  DistrictID,
  SubDistrictID,
};

export default masterProvice;
